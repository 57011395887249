import React from "react"
import Archivez from '../apollo/archives'
import { graphql } from "gatsby"
import SEO from "../components/seo"


import Footer from '../components/Footer'
import Modal from '../components/Modal'
import MRUDegreeModal from "../components/Modal/MRUDegree"
import MRUAchievementModal from "../components/Modal/MRUAchievement"
import MRUOutcomesModal from "../components/Modal/MRUOutcomes"

function Archives(props) {
	//const naviContext = useContext(NaviContext)
  const footerImage = props.data?.wordpressAcfOptions.options.footer_image?.localFile.childImageSharp.fluid

	// Just rerouting to apollo/archives
	return (
		<>

			<Modal style="degree-modal" trigger={"#modal-mru-degree-plan"}>
        <MRUDegreeModal />
      </Modal>

      <Modal style="acheviement-modal" trigger={"#modal-mru-achievement-data"}>
        <MRUAchievementModal  />
      </Modal>

      <Modal style="international-students" trigger={"#modal-mru-learning-outcomes"}>
				<MRUOutcomesModal />
      </Modal>


			<div id="mainframe-cover" className="layer">
				<div id="mainframe-bg-overlay" className="layer"></div>
				<div id="mainframe-stripes" className="layer">
					
				</div>
			</div>

			<main id="mainframe" className={`c0 archives main-${props.pageContext.slug === "/" ? "frontpage" : props.pageContext.slug}`}>
        <SEO title={`News`} description="Description" />

				<Archivez {...props} />

        <Footer image={footerImage} />

			</main>
		</>
	)
}

export default Archives

export const query = graphql`
  query {
    wpgraphql {
      ...wpNaviPrimary
      ...wpNaviSecondary
    }
    wordpressAcfOptions {
      options {
        footer_image {
          localFile {
            ...imgStandard
          }
        }
      }
    }
  }
`