import React from 'react'
import './style.scss'

function MRUDegreeModal() {


	return (
		<div id="tuition-modal" className="grid-12 c5 padd padd-top-2 padd-bottom-2">

			<div className='intro  fs-125'>
				<h2>Degree Plan</h2>
			</div>
			<div className='table container fs-85'>
			<p className='fs-85 w-100 span-12' style={{padding: "0 0 0.5em", margin: 0}}>Master of Business Administration Degree Plan</p>

				<table style={{margin: 0}} className='styled'>
					<thead>
						<tr>
							<th className='title'>CREDITS</th>
							<th>COURSE CODE</th>
							<th>COURSE NAME</th>

						</tr>
					</thead>
					<tbody>
						<tr>
							<td>3</td>
							<td >MAN5100</td>
							<td>Leadership and Organizational Behavior</td>
						</tr>
						<tr>
							<td>3</td>
							<td >MAN5200</td>
							<td>Human Resources Management</td>
						</tr>
						<tr>
							<td>3</td>
							<td >QMB5300</td>
							<td>Business Research Methods</td>
						</tr>
						<tr>
							<td>3</td>
							<td >ACG5500</td>
							<td>Managerial Accounting</td>
						</tr>
						<tr>
							<td>3</td>
							<td >MAN6200</td>
							<td>International Strategic Management</td>
						</tr>
						<tr>
							<td>3</td>
							<td >BUL5300</td>
							<td>Business Law</td>
						</tr>
						<tr>
							<td>3</td>
							<td >ECO6060</td>
							<td>Managerial Economics</td>
						</tr>
						<tr>
							<td>3</td>
							<td >MAN5400</td>
							<td>Operations and Project Management</td>
						</tr>
						<tr>
							<td>3</td>
							<td >MKT5300</td>
							<td>Marketing Management</td>
						</tr>
						<tr>
							<td>3</td>
							<td >MAN5300</td>
							<td>Information and Technology Management</td>
						</tr>
						<tr>
							<td>3</td>
							<td >FIN6100</td>
							<td>Financial Management</td>
						</tr>
						<tr>
							<td>3</td>
							<td >MAN6400</td>
							<td>Capstone: Business Strategies</td>
						</tr>
						<tr>
							<td>36</td>
							<td ></td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>

			<div style={{margin: 0}} className='table container fs-85'>
				<table>
					<thead>
						<tr>
							<th ><strong>PROGRAM</strong></th>
							<th ><strong>DESCRIPTION</strong></th>
							<th ><strong>OTHER COSTS ESTIMATED</strong></th>
							<th ><strong>EXPLANATION</strong></th>

						</tr>
					</thead>
					<tbody>

						<tr>
							<td>MBA</td>
							<td>Foreign Transcript Evaluation</td>
							<td>Variable</td>
							<td>Foreign Transcript Evaluation</td>
						</tr>
						<tr>
							<td>MBA</td>
							<td>Registration Fee</td>
							<td>$100.00</td>
							<td>Registration Fee</td>
						</tr>
						<tr>
							<td>MBA</td>
							<td>Application Fee</td>
							<td>$100.00</td>
							<td>Application Fee</td>
						</tr>
						<tr>
							<td>MBA</td>
							<td>Bring Your Own Device (BOD) App</td>
							<td>$99.00</td>
							<td>Tablet Configuration</td>
						</tr>
						<tr>
							<td>MBA</td>
							<td>Copies of academic records – cost per page</td>
							<td>$1.00</td>
							<td>Copies of academic records</td>
						</tr>
						<tr>
							<td>MBA</td>
							<td>Graduation Fee</td>
							<td>$150.00</td>
							<td>Graduation Fee</td>
						</tr>
						<tr>
							<td>MBA</td>
							<td>Parking Fee (Per Term)</td>
							<td>$25.00</td>
							<td>Cost to park in MRU assigned parking</td>
						</tr>
						<tr>
							<td>MBA</td>
							<td>Student Badge ID</td>
							<td>$50.00</td>
							<td>Student ID</td>
						</tr>
						<tr>
							<td>MBA</td>
							<td>Student Badge ID Replacement Fee</td>
							<td>$25.00</td>
							<td>Replacement of lost or stolen ID</td>
						</tr>
						<tr>
							<td>MBA</td>
							<td>Student Services, Technology and Media Fee (Per Semester)</td>
							<td>$120.00</td>
							<td>Cost for use of internet, WIFI, Online library periodicals, etc.</td>
						</tr>
						<tr>
							<td>MBA</td>
							<td>Transfer Credit Evaluation</td>
							<td>$120.00</td>
							<td>Cost to Evaluate transcripts for Transfer Credits</td>
						</tr>
						<tr>
							<td>MBA</td>
							<td>Tuition Cost per Credit</td>
							<td>$431.00</td>
							<td>Tuition Cost per Credit</td>
						</tr>
					</tbody>
				</table>
				<p className='fs-85 w-100 span-12' style={{padding: "2em 0 0", margin: 0}}>Grading policy for this program can be found on page 94 of the university catalog:
					<ul style={{paddingTop: '1em'}}>
						<li>
							<a target='_blank' href={'https://cms.mru.edu/wp-content/uploads/2022/04/ACADEMIC-ACHIEVEMENT-AND-GRADING-.pdf'}>Download the grading policy</a>
						</li>
						<li>
							<a target='_blank' href={'https://cms.mru.edu/wp-content/uploads/2023/07/MRU-University-Catalog-April-2023-2.pdf'}>Download the university catalog</a>
						</li>
					</ul>
				</p>

			</div>
		</div>
	)
}

export default MRUDegreeModal