import React from 'react'
import './style.scss'

function MRUAchievementModal() {


	return (
		<div id="tuition-modal" className="grid-12 c5 padd padd-top-2 padd-bottom-2">

			<div className='intro fs-125'>
				<h2>Student Achievement Data</h2>
			</div>

			<div className='table container fs-85'>
				<table>
					<thead>
						<tr>
							<th><strong>Year</strong></th>
							<th><strong>Graduation Rate</strong></th>
							<th><strong>Placement Rate</strong></th>
							<th><strong>Licensure Rate</strong></th>
						</tr>
					</thead>
					<tbody>

						<tr>
							<td ><strong>2022 – 2023</strong></td>
							<td >100%</td>
							<td >100%*</td>
							<td >NA</td>
						</tr>
						<tr>
							<td><strong>2021 – 2022</strong></td>
							<td>100%</td>
							<td>100%</td>
							<td>NA</td>
						</tr>
						<tr>
							<td ><strong>2020 – 2021</strong></td>
							<td >100%</td>
							<td >100%</td>
							<td >NA</td>
						</tr>
						<tr>
							<td><strong>2019 – 2020</strong></td>
							<td>100%</td>
							<td>89%</td>
							<td>NA</td>
						</tr>
						<tr>
							<td ><strong>2018 – 2019</strong></td>
							<td >NA</td>
							<td >NA</td>
							<td >NA</td>
						</tr>
					</tbody>
				</table>
			</div>


		</div>
	)
}

export default MRUAchievementModal