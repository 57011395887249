import React from 'react'
import './style.scss'

function MRUOutcomesModal() {

	return (
		<div id="campus-life-modal" className="grid-12 c5 padd-top padd-bottom">

			<div className='span-12 padd-2 span-12-tablet'>

				<div className='intro  fs-125'>
					<h2>End of Program <br />
						Student Learning Outcomes</h2>
				</div>

				<div className='grid-12 padd-top gap-1'>


					<div className='container fs-85'>
						<p className='padd-top'>
							At completion of the program, the graduate will possess the knowledge and skill to:
						</p>
						<ol>
							<li>Manage in a competitive and a changing business environment by integrating managerial competencies and strategic skills.</li>
							<li>Demonstrate leadership and team building skills critical to managing change and operational effectiveness.</li>
							<li>Use ethical practices and apply legal and social responsibilities in the decision-making process.</li>
							<li>Demonstrate the principles and processes of effective written and oral communications through research papers and presentations.</li>
							<li>Impact management effectiveness through cultural sensitivity and awareness.</li>
							<li>Enhance business decisions by critically evaluating an organization’s financial position and apply selected methods of quantitative and qualitative analysis.</li>
							<li>Generate creative solutions through the application of analytical models.</li>
						</ol>
					</div>

				</div>
			</div>

		</div>
	)
}

export default MRUOutcomesModal